/* eslint-disable no-nested-ternary */

import { Path } from 'react-router-dom';
import { halfwayIA } from '@gonfalon/dogfood-flags';

import { matchAppRoute } from './internal';

export function matchApprovalDashboard(path: Path) {
  return matchAppRoute(halfwayIA() ? '/projects/:projectKey/approvals' : '/approvals', path);
}

export function matchApprovalDetails(path: Path) {
  return matchAppRoute(
    halfwayIA() ? '/projects/:projectKey/approvals/:approvalRequestId' : '/approvals/:approvalRequestId',
    path,
  );
}

export function matchFlagApproval(path: Path) {
  return matchAppRoute(
    halfwayIA()
      ? '/projects/:projectKey/flags/:flagKey/approvals/:approvalRequestId'
      : '/:projKey/:envKey/features/:flagKey/approvals/:approvalRequestId',
    path,
  );
}

export function matchFlagTargetingScheduledChanges(path: Path) {
  return matchAppRoute(
    halfwayIA()
      ? '/projects/:projectKey/flags/:flagKey/targeting/pending-changes'
      : '/:projKey/:envKey/features/:flagKey/targeting/pending-changes',
    path,
  );
}

export function matchFlagTargetingScheduledChange(path: Path) {
  return matchAppRoute(
    halfwayIA()
      ? '/projects/:projectKey/flags/:flagKey/targeting/pending-changes/:pendingId'
      : '/:projKey/:envKey/features/:flagKey/targeting/pending-changes/:pendingId',
    path,
  );
}

export function matchFlagVariationsScheduledChanges(path: Path) {
  return matchAppRoute(
    halfwayIA()
      ? '/projects/:projectKey/flags/:flagKey/variations/pending-changes'
      : '/:projKey/:envKey/features/:flagKey/variations/pending-changes',
    path,
  );
}

export function matchFlagVariationScheduledChange(path: Path) {
  return matchAppRoute(
    halfwayIA()
      ? '/projects/:projectKey/flags/:flagKey/variations/pending-changes/:pendingId'
      : '/:projKey/:envKey/features/:flagKey/variations/pending-changes/:pendingId',
    path,
  );
}

export function matchFlagTargetingConflict(path: Path) {
  return matchAppRoute(
    halfwayIA()
      ? '/projects/:projectKey/flags/:flagKey/targeting/conflicts/:conflictIndex'
      : '/:projKey/:envKey/features/:flagKey/targeting/conflicts/:conflictIndex',
    path,
  );
}

export function matchFlagVariationConflict(path: Path) {
  return matchAppRoute(
    halfwayIA()
      ? '/projects/:projectKey/flags/:flagKey/variations/conflicts/:conflictIndex'
      : '/:projKey/:envKey/features/:flagKey/variations/conflicts/:conflictIndex',
    path,
  );
}

export function matchExperimentDesign(path: Path) {
  return matchAppRoute(
    halfwayIA()
      ? '/projects/:projectKey/experiments/:experimentKey/design'
      : '/:projKey/:envKey/experiments/:experimentKey/design',
    path,
  );
}

export function matchWorkflows(path: Path) {
  return matchAppRoute(
    halfwayIA() ? '/projects/:projectKey/flags/:flagKey/workflows' : '/:projKey/:envKey/features/:flagKey/workflows',
    path,
  );
}

export function matchWorkflow(path: Path) {
  return matchAppRoute(
    halfwayIA()
      ? '/projects/:projectKey/flags/:flagKey/workflows/:workflowId'
      : '/:projKey/:envKey/features/:flagKey/workflows/:workflowId',
    path,
  );
}

export function matchFlags(path: Path) {
  return matchAppRoute(halfwayIA() ? '/projects/:projectKey/flags' : '/:projKey/:envKey/features', path);
}

export function matchFlag(path: Path) {
  return matchAppRoute(
    halfwayIA() ? '/projects/:projectKey/flags/:flagKey' : '/:projKey/:envKey/features/:flagKey',
    path,
  );
}

export function matchFlagTargeting(path: Path) {
  return matchAppRoute(
    halfwayIA() ? '/projects/:projectKey/flags/:flagKey/targeting' : '/:projKey/:envKey/features/:flagKey/targeting',
    path,
  );
}

export function matchContexts(path: Path) {
  return matchAppRoute(halfwayIA() ? '/projects/:projectKey/contexts' : '/:projKey/:envKey/contexts', path);
}

export function matchContext(path: Path) {
  return matchAppRoute(halfwayIA() ? '/projects/:projectKey/contexts' : '/:projKey/:envKey/contexts', path);
}

export function matchContextDetails(path: Path) {
  return matchAppRoute(
    halfwayIA() ? '/projects/:projectKey/contexts/kinds/:kind/instances/:key' : '/:projKey/:envKey/contexts/:kind/:key',
    path,
  );
}

export function matchSegments(path: Path) {
  return matchAppRoute(halfwayIA() ? '/projects/:projectKey/segments' : '/:projKey/:envKey/segments', path);
}

export function matchExperiments(path: Path) {
  return matchAppRoute(halfwayIA() ? '/projects/:projectKey/experiments' : '/:projKey/:envKey/experiments', path);
}

export function matchFlagExperiments(path: Path) {
  return matchAppRoute('/:projKey/:envKey/features/:flagKey/experiments', path);
}

export function matchLegacyExperiments(path: Path) {
  return matchAppRoute('/:projKey/:envKey/experiments/legacy', path);
}

export function matchLive(path: Path) {
  return matchAppRoute(halfwayIA() ? '/projects/:projectKey/live' : '/:projKey/:envKey/live', path);
}

export function matchMetrics(path: Path) {
  return halfwayIA()
    ? matchAppRoute('/projects/:projectKey/metrics/:metricKey/details', path)
    : matchAppRoute('/projects/:projectKey/metrics/:metricKey/details', path);
}

export function matchAccountHistory(path: Path) {
  return matchAppRoute('/settings/history/*', path);
}

export function matchNewHires(path: Path) {
  return matchAppRoute('/internal/new-hires', path);
}

export function matchFlagCreation(path: Path) {
  return matchAppRoute(
    halfwayIA() ? '/projects/:projectKey/flags/new' : '/:projKey/:envKey/features/create-flag',
    path,
  );
}

export function matchGetStarted(path: Path) {
  return matchAppRoute(halfwayIA() ? '/projects/:projectKey/get-started' : '/:projKey/:envKey/get-started', path);
}

export function matchQuickstart(path: Path) {
  return matchAppRoute(
    halfwayIA() ? '/projects/:projectKey/get-started/quickstart' : '/:projKey/:envKey/get-started/quickstart',
    path,
  );
}

export function matchSetupCLI(path: Path) {
  return matchAppRoute(
    halfwayIA() ? '/projects/:projectKey/get-started/setup-cli' : '/:projKey/:envKey/get-started/setup-cli',
    path,
  );
}

export function matchInviteMembers(path: Path) {
  return matchAppRoute(
    halfwayIA() ? '/projects/:projectKey/get-started/invite-members' : '/:projKey/:envKey/get-started/invite-members',
    path,
  );
}

export function matchCreateReleasePipeline(path: Path) {
  return matchAppRoute(
    halfwayIA()
      ? '/projects/:projectKey/settings/release-pipelines/new'
      : '/settings/projects/:projKey/release-pipelines/new',
    path,
  );
}

export function matchEditReleasePipeline(path: Path) {
  return matchAppRoute(
    halfwayIA()
      ? '/projects/:projectKey/settings/release-pipelines/:releasePipelineKey'
      : '/settings/projects/:projKey/release-pipelines/:releasePipelineKey',
    path,
  );
}

export function matchPayloadFilterEdit(path: Path) {
  return matchAppRoute(
    halfwayIA()
      ? '/projects/:projectKey/settings/payload-filters/:filterKey/edit'
      : '/settings/projects/:projKey/payload-filters/:filterKey/edit',
    path,
  );
}
