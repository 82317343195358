import { GetCustomRolesOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

// unpaginated
export const apiVersion = '20220603';

export type GetCustomRolesQueryParams = GetCustomRolesOpenAPIQueryParams & {
  filter?: {
    query?: string;
  };
};

export function querySerializer(queryParams: GetCustomRolesQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.limit) {
    searchParams.set('limit', queryParams.limit.toString());
  }

  if (queryParams.offset) {
    searchParams.set('offset', queryParams.offset.toString());
  }

  // only available on API version '20240415' and above
  if (queryParams.filter?.query) {
    // filter is just a string, no filtering on specific properties so no need to serialize further
    searchParams.set('filter', queryParams.filter.query);
  }

  return searchParams.toString();
}
