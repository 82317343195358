import { deleteCustomRole, getCustomRole, getCustomRoles, patchCustomRole, postCustomRole } from '@gonfalon/openapi';

import { createMutationHook } from './internal/createMutationHookExperimental';
import { createQueryOptions } from './internal/createQueryOptions';

export const getCustomRolesQuery = createQueryOptions(getCustomRoles);
export const getCustomRoleQuery = createQueryOptions(getCustomRole);
export const useCreateCustomRole = createMutationHook(postCustomRole);
export const usePatchCustomRole = createMutationHook(patchCustomRole);
export const useDeleteCustomRole = createMutationHook(deleteCustomRole);
