import { patchSegment } from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHookExperimental';
import { segments } from './internal/queries';

async function patchSemantically(
  input: Parameters<typeof patchSegment>[0],
  options: Parameters<typeof patchSegment>[1] = {},
) {
  return patchSegment(input, {
    ...options,
    headers: {
      ...options.headers,
      'Content-Type': 'application/json; domain-model=launchdarkly.semanticpatch',
    },
  });
}

export const useUpdateSegmentSemantically = createMutationHook(patchSemantically, {
  onSuccess: async (data, { projectKey, environmentKey, segmentKey }) => {
    const queryClient = getQueryClient();

    await queryClient.invalidateQueries({ queryKey: segments.list._def });
    await queryClient.invalidateQueries({
      queryKey: segments.detail({ projectKey, environmentKey, segmentKey }).queryKey,
    });
  },
});
