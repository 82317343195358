import {
  deleteAiConfig,
  deleteAiConfigPublishedVersion,
  deleteAiConfigVersion,
  getAiConfig,
  getAiConfigPublishedVersions,
  getAiConfigs,
  getAiConfigVersion,
  patchAiConfigVersion,
  postCreateAiConfig,
  putAiConfigPublishedVersion,
} from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHookExperimental';
import { createQueryOptions } from './internal/createQueryOptions';

// fetch a list of AI Configs for dashboard
export const getAiConfigsQuery = createQueryOptions(getAiConfigs);
// fetch all versions of a single AI Config for detail page. Render models and prompts
export const getAiConfigQuery = createQueryOptions(getAiConfig);
// fetch published versions of the AI Config. Example the latest variation for a rule dropdown in the targeting page
export const getAiConfigPublishedVersionsQuery = createQueryOptions(getAiConfigPublishedVersions);
// fetch a single version of a single AI Config. Note: 09/11/24 Not needed now per designs
export const getAiConfigVersionQuery = createQueryOptions(getAiConfigVersion);

// create an AI Config
export const useCreateAiConfig = createMutationHook(postCreateAiConfig);
// update the selected version (modal & prompt) in an AI Config. Fetch latest version afer update
export const useUpdateAiConfig = createMutationHook(patchAiConfigVersion, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: getAiConfigQuery.partialQueryKey() });
  },
});

// delete all ai config versions. Basically delete everything. Refetch a list of AI configs
export const useDeleteAiConfig = createMutationHook(deleteAiConfig, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.refetchQueries({ queryKey: getAiConfigQuery.partialQueryKey() });
  },
});

// delete a single ai config version (model & prompt). Retain any existing version (model & prompts). Refetch vall there AI Config versions
export const useDeleteAiConfigVersion = createMutationHook(deleteAiConfigVersion, {
  onSuccess: async (data, { configKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: getAiConfigQuery.partialQueryKey({ configKey }) });
  },
});

// delete a single AI Config published version. Basically anything shown in the prompts tab
// Note: the backend retains unpublished version but today (09/11/24) we dont do anything with them
export const useDeleteAiConfigPublishedVersion = createMutationHook(deleteAiConfigPublishedVersion, {
  onSuccess: async (data, { configKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: getAiConfigQuery.partialQueryKey({ configKey }) });
  },
});

// update a single AI Config published version. Example update targeting page
export const usePutAiConfigPublishedVersion = createMutationHook(putAiConfigPublishedVersion, {
  onSuccess: async (data, { configKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: getAiConfigQuery.partialQueryKey({ configKey }) });
  },
});
