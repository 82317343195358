/* eslint-disable no-nested-ternary */

import { URLSearchParamsInit } from 'react-router-dom';
import { enableSegmentApprovals, halfwayIA, isNewCreateExperimentFlowEnabled } from '@gonfalon/dogfood-flags';
// eslint-disable-next-line no-restricted-imports
import { appRoute, unauthenticatedRoute } from '@gonfalon/router';
import nullthrows from 'nullthrows';

import { withChangeHistory } from './internal/withChangeHistory';
import { withNewProjectContext } from './internal/withNewProjectContext';
import { withProjectContext } from './internal/withProjectContext';
import { buildPath, withHash, withSearchParams } from './internal';

export { toHref } from './toHref';
export * from './matchers';

type URLOptions = {
  search?: URLSearchParamsInit;
  hash?: string;
};

type WithChangeHistory<T extends object> = T & { changeHistory?: true | string };

export { matchAppRoute } from './internal/matchAppRoute';

export function toRoot({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/'), withSearchParams(search), withHash(hash));
}

export function toProjectHome({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/projects/:projectKey', { projectKey }), withSearchParams(search), withHash(hash));
}

export function toFlags(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/flags', { projectKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext(),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features', { projKey: projectKey, envKey: environmentKey }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toCreateSegment(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/segments/new', { projectKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/segments/new', { projKey: projectKey, envKey: environmentKey }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toSegments(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/segments', { projectKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/segments', { projKey: projectKey, envKey: environmentKey }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toLegacyExperiments(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/:projKey/:envKey/experiments/legacy', { projKey: projectKey, envKey: environmentKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toMetricGroups({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/metrics/groups', { projectKey }),
        withSearchParams(search),
        withHash(hash),
      )
    : buildPath(
        appRoute('/:projKey/metrics/groups', { projKey: projectKey }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toMetricAnalytics(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/metrics/analytics', { projectKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/metrics/analytics', { projKey: projectKey, envKey: environmentKey }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toAnalyticsQueryResults(
  {
    projectKey,
    environmentKey,
    analyticsQueryResultId,
  }: { projectKey: string; environmentKey: string; analyticsQueryResultId: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/metrics/analytics/new/:analyticsQueryResultId', {
          projectKey,
          analyticsQueryResultId,
        }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/metrics/analytics/new/:analyticsQueryResultId', {
          projKey: projectKey,
          envKey: environmentKey,
          analyticsQueryResultId,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toMetricDiscovery(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/metrics/discover', { projectKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/metrics/discover', { projKey: projectKey }),
        withSearchParams({ env: environmentKey }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toMetricImpact(
  { projectKey, metricKey }: { projectKey: string; metricKey: string },
  { hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(appRoute('/projects/:projectKey/metrics/:metricKey/impact', { projectKey, metricKey }), withHash(hash))
    : buildPath(appRoute('/:projKey/metrics/:metricKey/impact', { projKey: projectKey, metricKey }), withHash(hash));
}

export function toMetricGroup(
  {
    projectKey,
    metricGroupKey,
  }: {
    projectKey: string;
    metricGroupKey: string;
  },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/metrics/groups/:metricGroupKey/details', { projectKey, metricGroupKey }),
        withSearchParams(search),
        withHash(hash),
      )
    : buildPath(
        appRoute('/:projKey/metrics/groups/:metricGroupKey/details', { projKey: projectKey, metricGroupKey }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toMetricDetails(
  { projectKey, metricKey }: { projectKey: string; metricKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/metrics/:metricKey/details', { projectKey, metricKey }),
        withSearchParams(search),
        withHash(hash),
      )
    : buildPath(
        appRoute('/projects/:projectKey/metrics/:metricKey/details', { projectKey, metricKey }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toMetric(
  { projectKey, metricKey }: { projectKey: string; metricKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/metrics/:metricKey/details', {
          projectKey,
          metricKey,
        }),
        withSearchParams(search),
        withHash(hash),
      )
    : buildPath(
        appRoute('/:projKey/metrics/:metricKey/details', { projKey: projectKey, metricKey }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toMetrics({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return halfwayIA()
    ? buildPath(appRoute('/projects/:projectKey/metrics', { projectKey }), withSearchParams(search), withHash(hash))
    : buildPath(
        appRoute('/:projKey/metrics', {
          projKey: projectKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toExperiments(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/experiments', { projectKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/experiments', { projKey: projectKey, envKey: environmentKey }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toHoldoutsRoot({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/projects/:projectKey/holdouts', { projectKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toHoldoutsCreate({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/projects/:projectKey/holdouts/create', { projectKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toHoldoutDetails(
  { projectKey, holdoutKey, environmentKey }: { projectKey: string; holdoutKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/holdouts/:holdoutKey/details', { projectKey, holdoutKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toLiveEvents(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/live', { projectKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/live', { projKey: projectKey, envKey: environmentKey }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toLiveFlagEvents(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/live/flags/:flagKey', { projectKey, flagKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/live/flags/:flagKey', { projKey: projectKey, flagKey, envKey: environmentKey }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toSessions(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/sessions', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toSessionDetails(
  { projectKey, environmentKey, sessionId }: { projectKey: string; environmentKey: string; sessionId: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/sessions/:sessionId/*', { projectKey, sessionId }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toIssues(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/issues', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toIssueDetails(
  { projectKey, environmentKey, issueId }: { projectKey: string; environmentKey: string; issueId: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/issues/:issueId', { projectKey, issueId }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toIssueDetailsJSON(
  { projectKey, environmentKey, issueId }: { projectKey: string; environmentKey: string; issueId: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/issues/:issueId/json', { projectKey, issueId }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext({ selectedEnvironmentKey: environmentKey }),
  );
}

export function toAuditLog(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/:projKey/:envKey/audit/*', { projKey: projectKey, envKey: environmentKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toFlag(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/flags/:flagKey', {
          projectKey,
          flagKey,
        }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features/:flagKey', {
          projKey: projectKey,
          envKey: environmentKey,
          flagKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toFlagTargeting(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash, changeHistory }: WithChangeHistory<URLOptions> = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/flags/:flagKey/targeting', {
          projectKey,
          flagKey,
        }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
        withChangeHistory(changeHistory),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features/:flagKey/targeting', {
          projKey: projectKey,
          envKey: environmentKey,
          flagKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toSegmentTargeting(
  { projectKey, segmentKey, environmentKey }: { projectKey: string; segmentKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/segments/:segmentKey/targeting', {
          projectKey,
          segmentKey,
        }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/segments/:segmentKey/targeting', {
          projKey: projectKey,
          envKey: environmentKey,
          segmentKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toSegmentOverview(
  { projectKey, segmentKey, environmentKey }: { projectKey: string; segmentKey: string; environmentKey: string },
  { search, hash, changeHistory }: WithChangeHistory<URLOptions> = {},
) {
  return halfwayIA()
    ? redirectToSegmentTargeting({ projectKey, segmentKey, environmentKey }, { search, hash, changeHistory })
    : buildPath(
        appRoute('/:projKey/:envKey/segments/:segmentKey/overview', {
          projKey: projectKey,
          envKey: environmentKey,
          segmentKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

function redirectToSegmentTargeting(
  { projectKey, segmentKey, environmentKey }: { projectKey: string; segmentKey: string; environmentKey: string },
  { search, hash, changeHistory }: WithChangeHistory<URLOptions> = {},
) {
  // TODO: retire segment overview route when segment approvals is fully rolled out
  return enableSegmentApprovals()
    ? toSegmentTargeting({ projectKey, segmentKey, environmentKey }, { search, hash })
    : buildPath(
        appRoute('/projects/:projectKey/segments/:segmentKey/overview', {
          projectKey,
          segmentKey,
        }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
        withChangeHistory(changeHistory),
      );
}

export function toSegmentFlags(
  { projectKey, segmentKey, environmentKey }: { projectKey: string; segmentKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/segments/:segmentKey/overview', {
          projectKey,
          segmentKey,
        }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/segments/:segmentKey/overview', {
          projKey: projectKey,
          envKey: environmentKey,
          segmentKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toSegmentSettings(
  { projectKey, segmentKey, environmentKey }: { projectKey: string; segmentKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/segments/:segmentKey/settings', {
          projectKey,
          segmentKey,
        }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/segments/:segmentKey/settings', {
          projKey: projectKey,
          envKey: environmentKey,
          segmentKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toSegmentHistory(
  { projectKey, segmentKey, environmentKey }: { projectKey: string; segmentKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/segments/:segmentKey/history/*', {
          projectKey,
          segmentKey,
        }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/segments/:segmentKey/history/*', {
          projKey: projectKey,
          envKey: environmentKey,
          segmentKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toFlagVariations(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/flags/:flagKey/variations', {
          projectKey,
          flagKey,
        }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext(),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features/:flagKey/variations', {
          projKey: projectKey,
          envKey: environmentKey,
          flagKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toFlagHistory(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey?: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/:projKey/:envKey/features/:flagKey/history/*', {
      projKey: projectKey,
      envKey: nullthrows(environmentKey),
      flagKey,
    }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toFlagVariationPendingChanges(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/flags/:flagKey/variations/pending-changes', {
          projectKey,
          flagKey,
        }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features/:flagKey/variations/pending-changes', {
          projKey: projectKey,
          envKey: environmentKey,
          flagKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toFlagVariationConflict(
  {
    projectKey,
    flagKey,
    conflictIndex,
    environmentKey,
  }: { projectKey: string; flagKey: string; conflictIndex: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/flags/:flagKey/variations/conflicts/:conflictIndex', {
          projectKey,
          flagKey,
          conflictIndex,
        }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features/:flagKey/variations/conflicts/:conflictIndex', {
          projKey: projectKey,
          envKey: environmentKey,
          flagKey,
          conflictIndex: '*',
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toFlagTargetingConflict(
  {
    projectKey,
    flagKey,
    conflictIndex,
    environmentKey,
  }: { projectKey: string; flagKey: string; conflictIndex: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/flags/:flagKey/targeting/conflicts/:conflictIndex', {
          projectKey,
          flagKey,
          conflictIndex,
        }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features/:flagKey/targeting/conflicts/:conflictIndex', {
          projKey: projectKey,
          envKey: environmentKey,
          flagKey,
          conflictIndex: '*',
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toFlagEnvironmentSettings(
  { projectKey, flagKey }: { projectKey: string; flagKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/environments', {
      projectKey,
      flagKey,
    }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toFlagDefaults({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/projects/:projectKey/settings/flags', {
      projectKey,
    }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toFlagSettings(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/flags/:flagKey/settings', {
          projectKey,
          flagKey,
        }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features/:flagKey/settings', {
          projKey: projectKey,
          envKey: environmentKey,
          flagKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toFlagPendingChange(
  {
    projectKey,
    flagKey,
    pendingId,
    environmentKey,
  }: { projectKey: string; flagKey: string; pendingId: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/flags/:flagKey/targeting/pending-changes/:pendingId', {
          projectKey,
          flagKey,
          pendingId,
        }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features/:flagKey/targeting/pending-changes/:pendingId', {
          projKey: projectKey,
          envKey: environmentKey,
          flagKey,
          pendingId,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toFlagPendingTargetingChanges(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/flags/:flagKey/targeting/pending-changes', {
          projectKey,
          flagKey,
        }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features/:flagKey/targeting/pending-changes', {
          projKey: projectKey,
          envKey: environmentKey,
          flagKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toApprovals({ projectKey }: { projectKey?: string }, { search, hash }: URLOptions = {}) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/approvals', { projectKey: nullthrows(projectKey) }),
        withSearchParams(search),
        withHash(hash),
      )
    : buildPath(appRoute('/approvals'), withSearchParams(search), withHash(hash));
}

export function toApproval(
  { approvalRequestId, projectKey }: { projectKey?: string; approvalRequestId: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/approvals/:approvalRequestId', {
          approvalRequestId,
          projectKey: nullthrows(projectKey),
        }),
        withSearchParams(search),
        withHash(hash),
      )
    : buildPath(
        appRoute('/approvals/:approvalRequestId', { approvalRequestId }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toFlagApproval(
  {
    projectKey,
    flagKey,
    approvalRequestId,
    environmentKey,
  }: { projectKey: string; flagKey: string; approvalRequestId: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/flags/:flagKey/approvals/:approvalRequestId', {
          projectKey,
          flagKey,
          approvalRequestId,
        }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features/:flagKey/approvals/:approvalRequestId', {
          projKey: projectKey,
          envKey: environmentKey,
          flagKey,
          approvalRequestId,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toContexts(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/contexts', { projectKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/contexts', { projKey: projectKey, envKey: environmentKey }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toContextKinds(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/settings/context-kinds', { projectKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/contexts/kinds', { projKey: projectKey, envKey: environmentKey }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toConnectFlag(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/:projKey/:envKey/features/connect', { projKey: projectKey, envKey: environmentKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toCreateFlag(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/flags/new', {
          projectKey,
        }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features/create-flag', {
          projKey: projectKey,
          envKey: environmentKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toArchiveFlagFromList(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/:projKey/:envKey/features/:flagKey/archive', {
      projKey: projectKey,
      envKey: environmentKey,
      flagKey,
    }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toDeprecateFlagFromList(
  { projectKey, flagKey }: { projectKey: string; flagKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/settings/deprecate', { projectKey, flagKey }),
    withSearchParams(search),
    withHash(hash),
  );
}
export function toArchiveFlagFromSettings(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/flags/:flagKey/settings/archive', { projectKey, flagKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext(),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features/:flagKey/settings/archive', {
          projKey: projectKey,
          envKey: environmentKey,
          flagKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}
export function toDeprecateFlagFromSettings(
  { projectKey, flagKey }: { projectKey: string; flagKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/flags/:flagKey/settings/deprecate', { projectKey, flagKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toFlagOverview(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/flags/:flagKey/environment-overview', { projectKey, flagKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features/:flagKey/compare-flag', {
          projKey: projectKey,
          flagKey,
          envKey: environmentKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toFlagExperiments(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/:projKey/:envKey/features/:flagKey/experiments', {
      projKey: projectKey,
      envKey: environmentKey,
      flagKey,
    }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toFlagExperimentsSettings(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/:projKey/:envKey/features/:flagKey/experiments/settings', {
      projKey: projectKey,
      envKey: environmentKey,
      flagKey,
    }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toFlagComparison(
  {
    projectKey,
    environmentPair,
    environmentKey,
  }: { projectKey: string; environmentPair: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/compare/:environmentPair', { projectKey, environmentPair }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features/compare/:environmentPair', {
          projKey: projectKey,
          envKey: environmentKey,
          environmentPair,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toFlagSync(
  {
    projectKey,
    flagKey,
    environmentPair,
    environmentKey,
  }: { projectKey: string; flagKey: string; environmentPair: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/compare/:environmentPair/flags/:flagKey', {
          projectKey,
          flagKey,
          environmentPair,
        }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features/compare/:environmentPair/:flagKey', {
          projKey: projectKey,
          flagKey,
          envKey: environmentKey,
          environmentPair,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toContextInstance(
  { projectKey, instanceId, environmentKey }: { projectKey: string; instanceId: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/contexts/instances/:instanceId', { projectKey, instanceId }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/contexts/:instanceId', { projKey: projectKey, envKey: environmentKey, instanceId }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toContext(
  { projectKey, kind, key, environmentKey }: { projectKey: string; kind: string; key: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  const encodedKey = encodeURIComponent(key);
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/contexts/kinds/:kind/instances/:key', { projectKey, kind, key: encodedKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/contexts/:kind/:key', {
          projKey: projectKey,
          envKey: environmentKey,
          kind,
          key: encodedKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toFlagWorkflows(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/flags/:flagKey/workflows', { projectKey, flagKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features/:flagKey/workflows', {
          projKey: projectKey,
          envKey: environmentKey,
          flagKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toFlagInsights(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/flags/:flagKey/insights', { projectKey, flagKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features/:flagKey/insights', {
          projKey: projectKey,
          envKey: environmentKey,
          flagKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toFlagCodeReferences(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey?: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/flags/:flagKey/code-refs', { projectKey, flagKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext(),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features/:flagKey/code-refs', {
          projKey: projectKey,
          envKey: nullthrows(environmentKey),
          flagKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toFlagLinks(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/flags/:flagKey/links', { projectKey, flagKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features/:flagKey/links', {
          projKey: projectKey,
          envKey: environmentKey,
          flagKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toFlagWorkflow(
  {
    projectKey,
    flagKey,
    workflowId,
    environmentKey,
  }: { projectKey: string; flagKey: string; workflowId: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/flags/:flagKey/workflows/:workflowId', { projectKey, flagKey, workflowId }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features/:flagKey/workflows/:workflowId', {
          projKey: projectKey,
          envKey: environmentKey,
          flagKey,
          workflowId,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toCreateFlagWorkflow(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/flags/:flagKey/workflows/new', { projectKey, flagKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features/:flagKey/workflows/new', {
          projKey: projectKey,
          envKey: environmentKey,
          flagKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toFlagExcludeRules(
  { projectKey, flagKey, environmentKey }: { projectKey: string; flagKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/flags/:flagKey/targeting/exclude-rules', { projectKey, flagKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features/:flagKey/targeting/exclude-rules', {
          projKey: projectKey,
          envKey: environmentKey,
          flagKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toExperimentDesign(
  { projectKey, experimentKey, environmentKey }: { projectKey: string; experimentKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/experiments/:experimentKey/design', { projectKey, experimentKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/experiments/:experimentKey/design', {
          projKey: projectKey,
          envKey: environmentKey,
          experimentKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toExperimentResults(
  { projectKey, experimentKey, environmentKey }: { projectKey: string; experimentKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/experiments/:experimentKey/results', { projectKey, experimentKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/experiments/:experimentKey/results', {
          projKey: projectKey,
          envKey: environmentKey,
          experimentKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toCreateExperiment(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return isNewCreateExperimentFlowEnabled()
    ? halfwayIA()
      ? buildPath(
          appRoute('/projects/:projectKey/experiments/create', { projectKey }),
          withSearchParams(search),
          withHash(hash),
          withProjectContext({ selectedEnvironmentKey: environmentKey }),
        )
      : buildPath(
          appRoute('/:projKey/:envKey/experiments/create', { projKey: projectKey, envKey: environmentKey }),
          withSearchParams(search),
          withHash(hash),
        )
    : buildPath(
        appRoute('/:projKey/:envKey/experiments/new', {
          projKey: projectKey,
          envKey: environmentKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toCreateMetric(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/metrics/new', { projectKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/experiments/metrics/new', {
          projKey: projectKey,
          envKey: environmentKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toExperimentIteration(
  {
    projectKey,
    experimentKey,
    iterationId,
    environmentKey,
  }: { projectKey: string; experimentKey: string; iterationId: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/experiments/:experimentKey/iterations/:iterationId', {
          projectKey,
          experimentKey,
          iterationId,
        }),
        withSearchParams(search),
        withHash(hash),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/experiments/:experimentKey/iterations/:iterationId', {
          projKey: projectKey,
          envKey: environmentKey,
          experimentKey,
          iterationId,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toTeamHistory({ teamKey }: { teamKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/teams/:teamKey/history/*', { teamKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toReleasePipelines({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/settings/release-pipelines', { projectKey }),
        withSearchParams(search),
        withHash(hash),
      )
    : buildPath(
        appRoute('/settings/projects/:projKey/release-pipelines', { projKey: projectKey }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toReleasePipeline(
  { projectKey, releasePipelineKey }: { projectKey: string; releasePipelineKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/settings/release-pipelines/:releasePipelineKey', {
          projectKey,
          releasePipelineKey,
        }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext(),
      )
    : buildPath(
        appRoute('/settings/projects/:projKey/release-pipelines/:releasePipelineKey', {
          projKey: projectKey,
          releasePipelineKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toCreateReleasePipeline({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/settings/release-pipelines/new', { projectKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext(),
      )
    : buildPath(
        appRoute('/settings/projects/:projKey/release-pipelines/new', {
          projKey: projectKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toReleases(
  {
    projectKey,
    environmentKey,
    releasePipelineKey,
  }: { projectKey: string; environmentKey: string; releasePipelineKey?: string },
  { search, hash }: URLOptions = {},
) {
  if (!releasePipelineKey) {
    // this only happens if you try to navigate to the releases view when no release pipelines exist yet
    return toReleasePipelines({ projectKey }, { search, hash });
  }

  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/releases/:releasePipelineKey', { projectKey, releasePipelineKey }),
        withSearchParams(search),
        withHash(hash),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features/releases/:releasePipelineKey', {
          projKey: projectKey,
          envKey: environmentKey,
          releasePipelineKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toAccountSettings({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/general'), withSearchParams(search), withHash(hash));
}

export function toIntegrations({ projectKey }: { projectKey?: string } = {}, { search, hash }: URLOptions = {}) {
  return halfwayIA()
    ? buildPath(appRoute('/settings/integrations'), withSearchParams(search), withHash(hash))
    : buildPath(
        appRoute('/:projectKey/integrations', { projectKey: nullthrows(projectKey) }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toInstallSDK(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/install-sdk', { projectKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/get-started/install-sdk', { projKey: projectKey, envKey: environmentKey }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toJoinOrganization({ token }: { token: string }, { search }: URLOptions = {}) {
  return buildPath(unauthenticatedRoute('/join-your-organization/:token', { token }), withSearchParams(search));
}

export function toChooseOrganization({ email }: { email: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    unauthenticatedRoute('/choose-organization/:email', { email }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toOnboarding({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/projects/:projectKey/onboarding', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withProjectContext(),
  );
}

/**
 * Deprecated in favor of toChooseOrganizationCheckYourEmail.
 * Do not use this because it uses customer email in the url.
 *
 * Flag: enableCheckYourEmailEndpoint.
 */
export function toVerifyOrganizationEmail({ email }: { email: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    unauthenticatedRoute('/choose-organization/verify-email/:email', { email }),
    withSearchParams(search),
    withHash(hash),
  );
}

/**
 * Replaces toVerifyOrganizationEmail.
 * When enableCheckYourEmailEndpoint is true, the auth flow will use this function instead of toVerifyOrganizationEmail.
 */
export function toChooseOrganizationCheckYourEmail() {
  return buildPath(unauthenticatedRoute('/choose-organization/check-your-email'));
}

export function toVerifyEmail({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/verify-email'), withSearchParams(search), withHash(hash));
}

/**
 * Deprecated in favor of toCheckYourEmail.
 * Do not use this because it uses customer email in the url.
 *
 * Flag: enableCheckYourEmailEndpoint.
 */
export function toUnauthenticatedVerifyEmail({ email }: { email: string }, { search, hash }: URLOptions = {}) {
  return buildPath(unauthenticatedRoute('/verify-email/:email', { email }), withSearchParams(search), withHash(hash));
}

/**
 * Replaces toUnauthenticatedVerifyEmail.
 * When enableCheckYourEmailEndpoint is true, the auth flow will use this function instead of toUnauthenticatedVerifyEmail.
 */
export function toCheckYourEmail() {
  return buildPath(unauthenticatedRoute('/check-your-email'));
}

export function toVerifyOrganizationPassword(
  { email, selectedOrganization }: { email: string; selectedOrganization: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    unauthenticatedRoute('/choose-organization/:email/:selectedOrganization/enter-password', {
      email,
      selectedOrganization,
    }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toSignup({ search, hash }: URLOptions = {}) {
  return buildPath(unauthenticatedRoute('/signup'), withSearchParams(search), withHash(hash));
}

export function toJoin({ search, hash }: URLOptions = {}) {
  return buildPath(unauthenticatedRoute('/join'), withSearchParams(search), withHash(hash));
}

export function toLogin({ search, hash }: URLOptions = {}) {
  return buildPath(unauthenticatedRoute('/login'), withSearchParams(search), withHash(hash));
}

export function toBilling({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/billing'), withSearchParams(search), withHash(hash));
}

export function toBillingPlans({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/billing/plans'), withSearchParams(search), withHash(hash));
}

export function toReviewBillingPlan({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/billing/plans/review'), withSearchParams(search), withHash(hash));
}

export function toProjects({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/projects'), withSearchParams(search), withHash(hash));
}

export function toGeneralSettings({ search, hash, changeHistory }: WithChangeHistory<URLOptions> = {}) {
  return buildPath(
    appRoute('/settings/general'),
    withSearchParams(search),
    withHash(hash),
    withChangeHistory(changeHistory),
  );
}

export function toProject({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/projects/:projKey', { projKey: projectKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toProjectSettings(
  { projectKey }: { projectKey: string },
  { search, hash, changeHistory }: WithChangeHistory<URLOptions> = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/settings', { projectKey }),
    withSearchParams(search),
    withHash(hash),
    withChangeHistory(changeHistory),
  );
}

export function toProjectEnvironments({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/settings/environments', { projectKey }),
        withSearchParams(search),
        withHash(hash),
      )
    : buildPath(
        appRoute('/settings/projects/:projKey/environments', { projKey: projectKey }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toPayloadFiltersSettings({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/projects/:projectKey/settings/payload-filters', { projectKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toCreateEnvironment({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/settings/environments/new', { projectKey }),
        withSearchParams(search),
        withHash(hash),
      )
    : buildPath(
        appRoute('/settings/projects/:projKey/environments/new', { projKey: projectKey }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toEditProjectEnvironment(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/settings/environments/:envKey/edit', { projectKey, envKey: environmentKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/settings/projects/:projKey/environments/:envKey/edit', {
          projKey: projectKey,
          envKey: environmentKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toProjectEnvironmentApprovalSettings(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/settings/environments/:envKey/approval-settings', {
          projectKey,
          envKey: environmentKey,
        }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/settings/projects/:projKey/environments/:envKey/approval-settings', {
          projKey: projectKey,
          envKey: environmentKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toMembers({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/members'), withSearchParams(search), withHash(hash));
}

export function toMembersInvite({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/members/invite'), withSearchParams(search), withHash(hash));
}

export function toMemberPermissions({ memberId }: { memberId: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/members/:memberId/permissions', { memberId }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toMember({ memberId }: { memberId: string }, { search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/members/:memberId', { memberId }), withSearchParams(search), withHash(hash));
}

export function toMemberHistory({ memberId }: { memberId: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/members/:memberId/history/*', { memberId }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toMemberTeams({ memberId }: { memberId: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/members/:memberId/teams', { memberId }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toProfile({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/profile'), withSearchParams(search), withHash(hash));
}

export function toApplications({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/applications'), withSearchParams(search), withHash(hash));
}

export function toApplication({ applicationKey }: { applicationKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/applications/:applicationKey', { applicationKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toApplicationVersions(
  { applicationKey }: { applicationKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/settings/applications/:applicationKey/versions', { applicationKey }),
    withSearchParams(search),
    withHash(hash),
  );
}
export function toEditApplication({ applicationKey }: { applicationKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/applications/:applicationKey/edit', { applicationKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toApplicationHistory(
  { applicationKey }: { applicationKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/settings/applications/:applicationKey/history/*', { applicationKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toCreateApplicationVersion(
  { applicationKey }: { applicationKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/settings/applications/:applicationKey/versions/new', { applicationKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toCreateApplication({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/applications/new'), withSearchParams(search), withHash(hash));
}

export function toServerUsageDetails({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/usage/details/server'), withSearchParams(search), withHash(hash));
}

export function toClientUsageDetails({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/usage/details/client'), withSearchParams(search), withHash(hash));
}

export function toExperimentUsageDetails({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/usage/details/experiments'), withSearchParams(search), withHash(hash));
}

export function toDataExportUsageDetails({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/usage/details/data-export'), withSearchParams(search), withHash(hash));
}

export function toHostUsageDetails({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/usage/details/host'), withSearchParams(search), withHash(hash));
}

export function toUsageOverview({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/usage'), withSearchParams(search), withHash(hash));
}

export function toUsageDetailsOverview({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/usage/details/overview'), withSearchParams(search), withHash(hash));
}

export function toViewCustomRole({ roleKey }: { roleKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/roles/:key/view', { key: roleKey }), withSearchParams(search), withHash(hash));
}

export function toEditCustomRole({ roleKey }: { roleKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/roles/:key/edit', { key: roleKey }), withSearchParams(search), withHash(hash));
}

export function toSecurity({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/security'), withSearchParams(search), withHash(hash));
}

export function toAuthorization({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/authorization'), withSearchParams(search), withHash(hash));
}

export function toEditPayloadFilter(
  { projectKey, payloadFilterKey }: { projectKey: string; payloadFilterKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/settings/payload-filters/:filterKey/edit', {
          projectKey,
          filterKey: payloadFilterKey,
        }),
        withSearchParams(search),
        withHash(hash),
      )
    : buildPath(
        appRoute('/settings/projects/:projKey/payload-filters/:filterKey/edit', {
          projKey: projectKey,
          filterKey: payloadFilterKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toRelayProxies({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/relay'), withSearchParams(search), withHash(hash));
}

export function toCreateRelayProxy({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/relay/new'), withSearchParams(search), withHash(hash));
}

export function toCustomRoles({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/roles'), withSearchParams(search), withHash(hash));
}

export function toCreateCustomRole({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/roles/new'), withSearchParams(search), withHash(hash));
}

export function toTeams({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/teams'), withSearchParams(search), withHash(hash));
}

export function toTeamMembers({ teamKey }: { teamKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/teams/:teamKey/members', { teamKey }), withSearchParams(search), withHash(hash));
}

export function toTeamPermissions({ teamKey }: { teamKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/teams/:teamKey/permissions', { teamKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toTeamSettings({ teamKey }: { teamKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/teams/:teamKey/settings', { teamKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toAccountHistory({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/history/*', {}), withSearchParams(search), withHash(hash));
}

export function toAuthorizationCustomRoles({ roleKey }: { roleKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/authorization/roles/:key', { key: roleKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toCreateAccessToken({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/authorization/tokens/new'), withSearchParams(search), withHash(hash));
}

export function toEditAccessToken({ tokenId }: { tokenId: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/authorization/tokens/:tokenId/edit', { tokenId }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toResetAccessToken({ tokenId }: { tokenId: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/authorization/tokens/:tokenId/reset', { tokenId }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toCloneAccessToken({ tokenId }: { tokenId: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/authorization/tokens/:tokenId/clone', { tokenId }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toSAMLConfig({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/security/saml/config'), withSearchParams(search), withHash(hash));
}

export function toServiceTokens({ search, hash }: URLOptions = {}) {
  return buildPath(appRoute('/settings/tokens'), withSearchParams(search), withHash(hash));
}

export function toReviewOAuthApp({ appId }: { appId: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/settings/authorization/oauth/:appId/review', { appId }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toGettingStarted(
  {
    projectKey,
    environmentKey,
  }: {
    projectKey: string;
    environmentKey: string;
  },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/get-started', { projectKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(appRoute('/:projKey/:envKey/get-started', { projKey: projectKey, envKey: environmentKey }));
}

export function toQuickstart(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/get-started/quickstart', { projectKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(appRoute('/:projKey/:envKey/get-started/quickstart', { projKey: projectKey, envKey: environmentKey }));
}

export function toSetupCLI(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/get-started/setup-cli', { projectKey }),
        withSearchParams(search),
        withHash(hash),
      )
    : buildPath(appRoute('/:projKey/:envKey/get-started/setup-cli', { projKey: projectKey, envKey: environmentKey }));
}

export function toInviteMembers(
  { projectKey, environmentKey }: { projectKey: string; environmentKey: string },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/get-started/invite-members', { projectKey }),
        withSearchParams(search),
        withHash(hash),
        withProjectContext({ selectedEnvironmentKey: environmentKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/get-started/invite-members', { projKey: projectKey, envKey: environmentKey }),
      );
}

// Note: this navigator is temporary until we have useNavigator hook
export function toFlagTargetingShortcut(
  {
    projectKey,
    flagKey,
    environmentKey,
    environmentKeys,
  }: { projectKey: string; flagKey: string; environmentKey: string; environmentKeys: Set<string> },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/flags/:flagKey/targeting', {
          projectKey,
          flagKey,
        }),
        withHash(hash),
        withSearchParams(search),
        withNewProjectContext({ selectedEnvironmentKey: environmentKey, environmentKeys, projectKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features/:flagKey/targeting', {
          projKey: projectKey,
          envKey: environmentKey,
          flagKey,
        }),
        withSearchParams(search),
        withHash(hash),
      );
}

// Note: this navigator is temporary until we have useNavigator hook
export function toFlagsShortcut(
  {
    projectKey,
    environmentKey,
    environmentKeys,
  }: { projectKey: string; environmentKey: string; environmentKeys: Set<string> },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/flags', { projectKey }),
        withSearchParams(search),
        withHash(hash),
        withNewProjectContext({ selectedEnvironmentKey: environmentKey, environmentKeys, projectKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/features', { projKey: projectKey, envKey: environmentKey }),
        withSearchParams(search),
        withHash(hash),
      );
}

// Note: this navigator is temporary until we have useNavigator hook
export function toContextsShortcut(
  {
    projectKey,
    environmentKey,
    environmentKeys,
  }: { projectKey: string; environmentKey: string; environmentKeys: Set<string> },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/contexts', { projectKey }),
        withSearchParams(search),
        withHash(hash),
        withNewProjectContext({ selectedEnvironmentKey: environmentKey, environmentKeys, projectKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/contexts', { projKey: projectKey, envKey: environmentKey }),
        withSearchParams(search),
        withHash(hash),
      );
}

// Note: this navigator is temporary until we have useNavigator hook
export function toSegmentsShortcut(
  {
    projectKey,
    environmentKey,
    environmentKeys,
  }: { projectKey: string; environmentKey: string; environmentKeys: Set<string> },
  { search, hash }: URLOptions = {},
) {
  return halfwayIA()
    ? buildPath(
        appRoute('/projects/:projectKey/segments', { projectKey }),
        withSearchParams(search),
        withHash(hash),
        withNewProjectContext({ selectedEnvironmentKey: environmentKey, environmentKeys, projectKey }),
      )
    : buildPath(
        appRoute('/:projKey/:envKey/segments', { projKey: projectKey, envKey: environmentKey }),
        withSearchParams(search),
        withHash(hash),
      );
}

export function toAIConfigs({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/projects/:projectKey/ai-configs', { projectKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toAIConfig(
  { projectKey, configKey }: { projectKey: string; configKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/ai-configs/:configKey', { projectKey, configKey }),
    withSearchParams(search),
    withHash(hash),
  );
}
export function toAIConfigVersions(
  { projectKey, configKey }: { projectKey: string; configKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/ai-configs/:configKey/versions', { projectKey, configKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toAIConfigTargeting(
  { projectKey, configKey }: { projectKey: string; configKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/ai-configs/:configKey/targeting', { projectKey, configKey }),
    withSearchParams(search),
    withHash(hash),
  );
}
export function toAIConfigMonitoring(
  { projectKey, configKey }: { projectKey: string; configKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/ai-configs/:configKey/monitoring', { projectKey, configKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toAIConfigPlayground(
  { projectKey, configKey }: { projectKey: string; configKey: string },
  { search, hash }: URLOptions = {},
) {
  return buildPath(
    appRoute('/projects/:projectKey/ai-configs/:configKey/playground', { projectKey, configKey }),
    withSearchParams(search),
    withHash(hash),
  );
}

export function toCreateAIConfig({ projectKey }: { projectKey: string }, { search, hash }: URLOptions = {}) {
  return buildPath(
    appRoute('/projects/:projectKey/ai-configs/new', { projectKey }),
    withSearchParams(search),
    withHash(hash),
  );
}
