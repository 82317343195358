import {
  createIntegrationConfiguration,
  deleteIntegrationConfiguration,
  getAllIntegrationConfigurations,
  getIntegrationConfiguration,
  updateIntegrationConfiguration,
} from '@gonfalon/openapi';
import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHookExperimental';
import { createQueryOptions } from './internal/createQueryOptions';

export const integrationConfigurationQuery = createQueryOptions(getIntegrationConfiguration);
export const integrationConfigurationsQuery = createQueryOptions(getAllIntegrationConfigurations);

export const useCreateIntegrationConfiguration = createMutationHook(createIntegrationConfiguration, {
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: integrationConfigurationsQuery.partialQueryKey() });
  },
});

export const useUpdateIntegrationConfiguration = createMutationHook(updateIntegrationConfiguration, {
  onSuccess: async (data, { integrationConfigurationId }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({
      queryKey: integrationConfigurationQuery.partialQueryKey({ integrationConfigurationId }),
    });
    await queryClient.invalidateQueries({ queryKey: integrationConfigurationsQuery.partialQueryKey() });
  },
});

export const useDeleteIntegrationConfiguration = createMutationHook(deleteIntegrationConfiguration, {
  onSuccess: async (data, { integrationConfigurationId }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({
      queryKey: integrationConfigurationQuery.partialQueryKey({ integrationConfigurationId }),
    });
    await queryClient.invalidateQueries({ queryKey: integrationConfigurationsQuery.partialQueryKey() });
  },
});
