import {
  enableFlagCleanupStatesFilter,
  enableHoldoutsUI,
  flagListLimitGuardrail,
  getFlagListPageSize,
  isCodeReferencesEnabled,
  isDataExportEnabled,
  isDisableCodeReferencesWithOverrideEnabled,
} from '@gonfalon/dogfood-flags';
import invariant from 'tiny-invariant';

import { GetFeatureFlagsOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export interface GetFeatureFlagsQueryParams
  extends Omit<GetFeatureFlagsOpenAPIQueryParams, 'env' | 'sort' | 'state' | 'filter' | 'expand'> {
  env?: string[];
  sort?:
    | 'creationDate'
    | '-creationDate'
    | 'key'
    | '-key'
    | 'maintainerId'
    | '-maintainerId'
    | 'name'
    | '-name'
    | 'tags'
    | '-tags'
    | 'targetingModifiedDate'
    | '-targetingModifiedDate'
    | 'type'
    | '-type';
  filter?: {
    query?: string;
    type?: 'permanent' | 'temporary';
    status?: 'new' | 'inactive' | 'active' | 'launched';
    tags?: string[];
    hasExperiment?: boolean;
    hasDataExport?: boolean;
    evaluated?: { after: number };
    maintainerId?: string;
    targeting?: 'on' | 'off';
    maintainerTeamKey?: string;
    contextKindTargeted?: string;
    contextKindsEvaluated?: string[];
    creationDate?: { before: number };
    segmentTargeted?: string;
    codeReferences?: { min?: number; max?: number };
    followerId?: string;
    excludeSettings?: Array<'prerequisites' | 'on' | 'targets' | 'rules' | 'fallthrough' | 'offVariation'>;
    sdkAvailability?: 'anyClient' | 'server' | 'mobile' | 'client';
    filterEnv?: string;
    releasePipeline?: string;
    applicationEvaluated?: string;
    state?: 'live' | 'archived' | 'deprecated';
    staleStates?: 'readyForCodeRemoval' | 'readyToArchive';
    purpose?: 'holdout' | 'migration' | '!(holdout)' | '!(migration)';
  };
  expand?: Array<'measuredRolloutStatus' | 'processes' | 'archiveChecks'>;
}

function limitIsInRange(limit: number) {
  const clamped = Math.max(1, Math.min(limit || getFlagListPageSize(), getFlagListPageSize()));
  return clamped === limit;
}

export function querySerializer(params: GetFeatureFlagsQueryParams) {
  const searchParams = new URLSearchParams();

  const limit = params.limit;
  if (flagListLimitGuardrail()) {
    if (limit !== undefined) {
      invariant(limitIsInRange(limit), `Limit must be between 1 and ${getFlagListPageSize()}`);
      searchParams.set('limit', String(limit));
    }
  } else {
    if ((params.limit ?? 0) > 0) {
      searchParams.set('limit', String(params.limit));
    }
  }

  if (params.env) {
    for (const env of params.env) {
      searchParams.append('env', env);
    }
  }

  if ((params.offset ?? 0) > 0) {
    searchParams.set('offset', String(params.offset));
  }

  if (params.archived !== undefined) {
    searchParams.set('archived', String(params.archived === true));
  }

  if (params.compare !== undefined) {
    searchParams.set('compare', String(params.compare === true));
  }

  if (params.summary !== undefined) {
    searchParams.set('summary', String(params.summary === true));
  }

  if (params.sort) {
    searchParams.set('sort', params.sort);
  }

  if (params.filter) {
    const serializedFilter = serializeFlagListFilterParam(params.filter);
    if (serializedFilter) {
      searchParams.set('filter', serializedFilter);
    }
  }

  if (params?.expand) {
    searchParams.append('expand', params.expand.join(','));
  }

  return searchParams.toString();
}

export function serializeFlagListFilterParam(value: GetFeatureFlagsQueryParams['filter'] = {}) {
  const filter: string[] = [];

  if (value.query) {
    filter.push(`query:${encodeURIComponent(value.query)}`);
  }

  if (value.sdkAvailability) {
    filter.push(`sdkAvailability:${value.sdkAvailability}`);
  }

  if (value.filterEnv) {
    filter.push(`filterEnv:${value.filterEnv}`);
  }

  if (value.contextKindTargeted) {
    filter.push(`contextKindTargeted:${value.contextKindTargeted}`);
  }

  if (value.maintainerId) {
    filter.push(`maintainerId:${value.maintainerId}`);
  }

  if (value.maintainerTeamKey) {
    filter.push(`maintainerTeamKey:${value.maintainerTeamKey}`);
  }

  if (value.followerId) {
    filter.push(`followerId:${value.followerId}`);
  }

  if (value.hasExperiment !== undefined) {
    filter.push(`hasExperiment:${String(value.hasExperiment)}`);
  }

  if (isDataExportEnabled()) {
    if (value.hasDataExport !== undefined) {
      filter.push(`hasDataExport:${String(value.hasDataExport)}`);
    }
  }

  if (!isDisableCodeReferencesWithOverrideEnabled() && isCodeReferencesEnabled()) {
    if (value.codeReferences !== undefined) {
      const { min, max } = value.codeReferences;
      if (min !== undefined) {
        filter.push(`codeReferences.min:${min}`);
      }

      if (max !== undefined) {
        filter.push(`codeReferences.max:${max}`);
      }
    }
  }

  if (value.evaluated) {
    filter.push(`evaluated:${JSON.stringify(value.evaluated)}`);
  }

  if (value.creationDate) {
    filter.push(`creationDate:${JSON.stringify(value.creationDate)}`);
  }

  if (value.tags && value.tags.length > 0) {
    filter.push(`tags:${value.tags.map(encodeURIComponent).join('+')}`);
  }

  if (value.type) {
    filter.push(`type:${value.type}`);
  }

  if (value.targeting) {
    filter.push(`targeting:${value.targeting}`);
  }

  if (value.status) {
    filter.push(`status:${value.status}`);
  }

  if (value.segmentTargeted) {
    filter.push(`segmentTargeted:${value.segmentTargeted}`);
  }

  if (value.contextKindsEvaluated && value.contextKindsEvaluated.length > 0) {
    filter.push(`contextKindsEvaluated:${value.contextKindsEvaluated.join('+')}`);
  }

  if (value.releasePipeline) {
    filter.push(`releasePipeline:${value.releasePipeline}`);
  }

  if (value.applicationEvaluated) {
    filter.push(`applicationEvaluated:${value.applicationEvaluated}`);
  }

  if (value.state) {
    filter.push(`state:${value.state}`);
  }

  if (enableFlagCleanupStatesFilter() && value.staleStates) {
    filter.push(`staleState:${value.staleStates}`);
  }

  if (enableHoldoutsUI()) {
    filter.push('purpose:!(holdout)');
  }

  // aesthetics…
  filter.sort();

  return filter.join(',');
}

export const apiVersion = '20160426';
