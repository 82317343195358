import type { Member, MemberSummary } from './types';

export function memberFirstName(member: Member | MemberSummary) {
  return member.firstName;
}

function memberFullName(member: Member | MemberSummary) {
  return [member.firstName, member.lastName].join(' ').trim();
}

export function memberDisplayName(member: Member | MemberSummary): string {
  return memberFullName(member) || member.email;
}
