import {
  documentationUri,
  euCssStylesEnabled as isEUInstance,
  federalCssStylesEnabled as isFederalInstance,
} from '@gonfalon/constants';
import { startsWith } from '@gonfalon/strings';
import qs from 'qs';

import { predicateFor } from 'utils/validationUtils';

export function isAbsolute(url: string) {
  // cannot use the parseURL above since the browser automatically prepends the current
  // page's protocol by default
  return /^(?:\w+:)?\/\//.test(url.toString());
}

export function isSubpathOrSelf(currentUrl: string, url: string) {
  return currentUrl === url || startsWith(currentUrl, url);
}

export function addQueryParams(url: string, additionalParams: object) {
  const [path, queryString] = url.split('?');
  const params = qs.parse(queryString);
  const withRelayState = qs.stringify({
    ...params,
    ...additionalParams,
  });
  return `${path}?${withRelayState}`;
}

export function removeQueryParam(queryString: string, clearedParam: string) {
  const parsedQueryString = qs.parse(queryString, { ignoreQueryPrefix: true });
  delete parsedQueryString[clearedParam];
  return qs.stringify(parsedQueryString, { indices: false });
}

export function isValidPort(url: string) {
  try {
    const port = new URL(url).port;
    return port === '' || port === '80' || port === '443';
  } catch {
    return false;
  }
}

export const urlWithRelayState = (url: string, relayUrl: string) => addQueryParams(url, { RelayState: relayUrl });

export const isAbsoluteURL = predicateFor(
  (v: string) => !isAbsolute(v.toLowerCase()),
  () => 'This is not an absolute URL',
);

export const isValidURLPort = predicateFor(
  (v: string) => !isValidPort(v.toLowerCase()),
  () => 'This url contains a port that is not allowed. Use either port 80 or 443',
);

// path example: 'home/code'
// leading slash is optional '/home/code'
export function getDocumentationUrl(path: string = ''): string {
  const baseUrl = documentationUri() || 'https://docs.launchdarkly.com';
  const newUrl = new URL(path, baseUrl);

  if (isFederalInstance()) {
    newUrl.searchParams.append('site', 'federal');
  } else if (isEUInstance()) {
    newUrl.searchParams.append('site', 'eu');
  } else {
    newUrl.searchParams.append('site', 'launchDarkly');
  }
  return newUrl.toString();
}
