import { GetExperimentResultsQueryParams } from '@gonfalon/openapi';
import { QueryOptions, useQueries } from '@tanstack/react-query';

import { createQueryHook } from './internal/createQueryHook';
import { experiments } from './internal/queries';

export const useExperimentAnalysis = createQueryHook(experimentAnalysisQuery);

export function useExperimentAnalyses(
  {
    projectKey,
    environmentKey,
    experimentKey,
    metricKeys,
    params,
  }: {
    projectKey: string;
    environmentKey: string;
    experimentKey: string;
    metricKeys: string[];
    // TODO update with analysis-specific type when available
    params?: GetExperimentResultsQueryParams;
  },
  options?: QueryOptions,
) {
  return useQueries({
    ...options,
    queries: metricKeys.map((metricKey) =>
      experimentAnalysisQuery({ projectKey, environmentKey, experimentKey, metricKey, params }),
    ),
  });
}

function experimentAnalysisQuery({
  projectKey,
  environmentKey,
  experimentKey,
  metricKey,
  params,
}: {
  projectKey: string;
  environmentKey: string;
  experimentKey: string;
  metricKey: string;
  // TODO update with analysis-specific type when available
  params?: GetExperimentResultsQueryParams;
}) {
  return experiments.detail({ projectKey, environmentKey, experimentKey })._ctx.analysis({ metricKey, params });
}
