import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';
import { IssuePatchParams } from './types';

export async function patchIssue({
  projectKey,
  issueId,
  input,
}: {
  projectKey: string;
  issueId: string;
  input: IssuePatchParams;
}) {
  return reactQueryResponseAdapter(
    getClient().PATCH('/internal/projects/{projectKey}/issues/{issueId}', {
      headers: {
        'LD-API-Version': 'beta',
        'Content-Type': 'application/json; domain-model=launchdarkly.semanticpatch',
      },
      params: {
        path: { projectKey, issueId },
      },
      body: input,
    }),
  );
}
