import { createTrackerForCategory } from '@gonfalon/analytics';
import {
  enableSegmentApprovalSettings,
  enforceResourceNameLength,
  isEnvironmentCreationEnabled,
  isRequireChangeConfirmationEnabled,
  isRequireCommentsEnabled,
} from '@gonfalon/dogfood-flags';
import { keyBy } from '@gonfalon/es6-utils';
import { KEY_MAX_LENGTH } from '@gonfalon/strings';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map, OrderedMap, Record, Set } from 'immutable';

import { FieldIds, getFieldPrefix } from 'components/ResourceApprovalSettings/approvalSettingsUtils';
import { AccessChecks, allowDecision, createAccessDecision } from 'utils/accessUtils';
import { Member } from 'utils/accountUtils';
import { Color, createColor } from 'utils/colorUtils';
import { CreateFunctionInput, ImmutableMap } from 'utils/immutableUtils';
import { Link } from 'utils/linkUtils';
import { createProject, Project } from 'utils/projectUtils';
import { makeFilter, stringContains } from 'utils/stringUtils';
import { isInRange, isLength, isNotEmpty, isReservedKey, isValidTagList, validateRecord } from 'utils/validationUtils';

export const MAX_ENVIRONMENT_KEY_LENGTH = KEY_MAX_LENGTH;
export const MAX_ENVIRONMENT_NAME_LENGTH = 256;
export const DEFAULT_PRODUCTION_ENVIRONMENT_KEY = 'production';

export const truncateKey = (key: string) => key.substring(0, MAX_ENVIRONMENT_KEY_LENGTH);

type PubNubProps = {
  channel: string;
  cipherKey: string;
  pubnubUuid: string;
};

class PubNub extends Record<PubNubProps>({
  channel: '',
  cipherKey: '',
  pubnubUuid: '',
}) {}

export type EnvironmentProps = {
  _access?: AccessChecks;
  _links: ImmutableMap<{
    analytics: Link;
    apiKey: Link;
    mobileKey: Link;
    self: Link;
    snippet: Link;
  }>;
  _id: string;
  _pubnub?: PubNub;
  _followed: boolean;
  key: string;
  apiKey: string;
  mobileKey: string;
  name: string;
  color: Color;
  defaultTtl: number;
  secureMode: boolean;
  defaultTrackEvents: boolean;
  tags: Set<string>;
  critical: boolean;
  requireComments: boolean;
  confirmChanges: boolean;
  approvalSettings?: ApprovalSettings;
  resourceApprovalSettings?: Map<ApprovalResourceKind, ResourceApprovalSettings>;
};

type ApprovalSettingsProps = {
  serviceKind: string;
  serviceKindConfigurationId?: string;
  serviceConfig?: Map<$TSFixMe, $TSFixMe>;
  required: boolean;
  canReviewOwnRequest: boolean;
  bypassApprovalsForPendingChanges: boolean;
  minNumApprovals: number;
  canApplyDeclinedChanges: boolean;
  requiredApprovalTags: Set<string>;
  approvalRequirementStrategy: ApprovalRequirementStrategy;
  autoApplyApprovedChanges?: boolean;
};

export enum ApprovalResourceKind {
  FLAG = 'flag', // note: flags haven't been migrated to approvals v2 yet
  SEGMENT = 'segment',
}

type ResourceApprovalSettingsProps = ApprovalSettingsProps & {
  resourceKind: ApprovalResourceKind;
};

export enum ApprovalServiceKind {
  // TODO: Do we need to handle these custom approvals enum values specifically?
  CustomApprovalServiceKind = 'custom-approvals',
  CustomApprovalV2ServiceKind = 'custom-approvals-v2',
  LaunchDarklyApprovalServiceKind = 'launchdarkly',
  ServiceNowApprovalServiceKind = 'servicenow',
}

export enum ApprovalRequirementStrategy {
  NONE = 'NONE',
  ALL = 'ALL',
  BY_TAG = 'BY_TAG',
}

export class ApprovalSettings extends Record<ApprovalSettingsProps>({
  serviceKind: ApprovalServiceKind.LaunchDarklyApprovalServiceKind,
  serviceKindConfigurationId: undefined,
  serviceConfig: Map(),
  required: false,
  canReviewOwnRequest: false,
  bypassApprovalsForPendingChanges: false,
  minNumApprovals: 1,
  canApplyDeclinedChanges: true,
  requiredApprovalTags: Set<string>(),
  approvalRequirementStrategy: ApprovalRequirementStrategy.NONE,
  autoApplyApprovedChanges: undefined,
}) {
  getApprovalRequirementStrategy(): ApprovalRequirementStrategy {
    if (this.required) {
      return ApprovalRequirementStrategy.ALL;
    } else if (this.requiredApprovalTags.size > 0) {
      return ApprovalRequirementStrategy.BY_TAG;
    }

    return ApprovalRequirementStrategy.NONE;
  }
  toRep() {
    return fromJS(this.toJSON()).withMutations((map: ImmutableMap<ApprovalSettingsProps>) => {
      // clear any previously-selected tags if we are not requiring approval by tag
      if (map.get('approvalRequirementStrategy') !== ApprovalRequirementStrategy.BY_TAG) {
        map.set('requiredApprovalTags', Set<string>());
      }

      // remove "approvalRequirementStrategy", as it is not part of the approval settings data model
      map.remove('approvalRequirementStrategy');
    });
  }
}

export class ResourceApprovalSettings extends Record<ResourceApprovalSettingsProps>({
  serviceKind: ApprovalServiceKind.LaunchDarklyApprovalServiceKind,
  serviceKindConfigurationId: undefined,
  serviceConfig: Map(),
  required: false,
  canReviewOwnRequest: false,
  bypassApprovalsForPendingChanges: false,
  minNumApprovals: 1,
  canApplyDeclinedChanges: true,
  requiredApprovalTags: Set<string>(),
  approvalRequirementStrategy: ApprovalRequirementStrategy.NONE,
  autoApplyApprovedChanges: undefined,
  resourceKind: ApprovalResourceKind.FLAG,
}) {
  getApprovalRequirementStrategy(): ApprovalRequirementStrategy {
    if (this.required) {
      return ApprovalRequirementStrategy.ALL;
    } else if (this.requiredApprovalTags.size > 0) {
      return ApprovalRequirementStrategy.BY_TAG;
    }

    return ApprovalRequirementStrategy.NONE;
  }
  toRep(): ResourceApprovalSettings {
    return fromJS(this.toJSON()).withMutations((map: ImmutableMap<ResourceApprovalSettingsProps>) => {
      // clear any previously-selected tags if we are not requiring approval by tag
      if (map.get('approvalRequirementStrategy') !== ApprovalRequirementStrategy.BY_TAG) {
        map.set('requiredApprovalTags', Set<string>());
      }

      // remove "approvalRequirementStrategy", as it is not part of the approval settings data model
      map.remove('approvalRequirementStrategy');
    });
  }
}

export function createApprovalRequestSettings(props: CreateFunctionInput<ApprovalSettings> = {}) {
  const approvalSettings = props instanceof ApprovalSettings ? props : new ApprovalSettings(fromJS(props));

  return approvalSettings.withMutations((a) => {
    a.update('requiredApprovalTags', (requiredApprovalTags) =>
      requiredApprovalTags ? requiredApprovalTags.toSet() : Set<string>(),
    );
    a.update('approvalRequirementStrategy', (strategy) =>
      !Object.values(ApprovalRequirementStrategy).includes(strategy) || strategy === ApprovalRequirementStrategy.NONE
        ? a.getApprovalRequirementStrategy()
        : strategy,
    );
  });
}

export function createResourceApprovalRequestSettings(props: CreateFunctionInput<ResourceApprovalSettings> = {}) {
  const approvalSettings =
    props instanceof ResourceApprovalSettings ? props : new ResourceApprovalSettings(fromJS(props));

  return approvalSettings.withMutations((a) => {
    a.update('requiredApprovalTags', (requiredApprovalTags) =>
      requiredApprovalTags ? requiredApprovalTags.toSet() : Set<string>(),
    );
    a.update('approvalRequirementStrategy', (strategy) =>
      !Object.values(ApprovalRequirementStrategy).includes(strategy) || strategy === ApprovalRequirementStrategy.NONE
        ? a.getApprovalRequirementStrategy()
        : strategy,
    );
  });
}

export class Environment extends Record<EnvironmentProps>({
  defaultTrackEvents: false,
  _access: undefined,
  _links: Map(),
  _id: '',
  _pubnub: undefined,
  _followed: false,
  key: '',
  apiKey: '',
  mobileKey: '',
  name: '',
  color: createColor({ h: 0, s: 1, l: 0.5 }),
  defaultTtl: 0,
  secureMode: false,
  approvalSettings: undefined,
  resourceApprovalSettings: undefined,
  tags: Set(),
  requireComments: false,
  confirmChanges: false,
  critical: false,
}) {
  checkAccess({ profile }: { profile: Member }) {
    const access = this._access;

    if (profile.isReader()) {
      return () => createAccessDecision({ isAllowed: false, appliedRoleName: 'Reader' });
    }

    if (profile.hasStrictWriterRights() || !access || !access.get('denied')) {
      return allowDecision;
    }

    return (action?: string) => {
      const deniedAction = access.get('denied').find((v) => v.get('action') === action);
      if (deniedAction) {
        const reason = deniedAction.get('reason');
        const roleName = reason && reason.get('role_name');
        return createAccessDecision({
          isAllowed: false,
          appliedRoleName: roleName,
        });
      }
      return createAccessDecision({ isAllowed: true });
    };
  }

  selfLink() {
    return this.getIn(['_links', 'self', 'href']);
  }

  validate() {
    return validateRecord(
      this,
      isNotEmpty('name'),
      enforceResourceNameLength() ? isLength(1, MAX_ENVIRONMENT_NAME_LENGTH)('name') : () => undefined,
      isNotEmpty('key'),
      isReservedKey('key'),
      isLength(1, MAX_ENVIRONMENT_KEY_LENGTH)('key'),
      isNotEmpty('defaultTtl'),
      isInRange({ min: 0, max: 60 })('defaultTtl'),
      isNotEmpty('color'),
      isValidTagList('tags'),
      // TODO: refactor this predicate to a generic function for validating single nested Records
      (env) => {
        const validationResults = [];

        if (enableSegmentApprovalSettings()) {
          for (const value of Object.values<ResourceApprovalSettings & { requiredApprovalTags: string[] }>(
            env.resourceApprovalSettings,
          )) {
            if (
              value.resourceKind === ApprovalResourceKind.SEGMENT &&
              value.approvalRequirementStrategy === ApprovalRequirementStrategy.BY_TAG &&
              value.requiredApprovalTags.length === 0
            ) {
              validationResults.push({
                for: `${getFieldPrefix(value.resourceKind)}${FieldIds.REQUIRED_APPROVAL_TAGS_SELECT}`,
                errors: ['Select one or more tags'],
              });
            }
          }
        }

        // TODO remove this check when flag settings have been migrated in the backend
        if (
          env.approvalSettings?.approvalRequirementStrategy === ApprovalRequirementStrategy.BY_TAG &&
          env.approvalSettings?.requiredApprovalTags.length === 0
        ) {
          validationResults.push({
            for: `${getFieldPrefix(ApprovalResourceKind.FLAG)}${FieldIds.REQUIRED_APPROVAL_TAGS_SELECT}`,
            errors: ['Select one or more tags'],
          });
        }

        // validate that approval template is selected when service kind is not LaunchDarkly (this is mainly for servicenow)
        // this disables the submit button if the template is not selected
        // NOTE: all resource kinds will inherit the approval system settings from the flag settings until multiple approval systems are supported
        if (
          this.hasIntegrationApproval() &&
          env.approvalSettings?.serviceKind === ApprovalServiceKind.ServiceNowApprovalServiceKind &&
          (!env.approvalSettings?.serviceConfig || !env.approvalSettings?.serviceConfig.template)
        ) {
          validationResults.push({ for: 'config.template', errors: ['Change template is required'] });
        }

        return validationResults;
      },
    );
  }

  isNew() {
    return !this._id;
  }

  areReviewersRequired() {
    return this.approvalSettings?.serviceKind === ApprovalServiceKind.LaunchDarklyApprovalServiceKind;
  }

  hasIntegrationApproval() {
    return (
      !!this.approvalSettings?.serviceKind &&
      this.approvalSettings?.serviceKind !== ApprovalServiceKind.LaunchDarklyApprovalServiceKind
    );
  }

  toRep(): ImmutableMap<Omit<EnvironmentProps, 'color'> & { color: string }> {
    return fromJS(this.toJSON()).withMutations((map: ImmutableMap<EnvironmentProps>) => {
      map.set('color', this.color.hex());
      map.update('approvalSettings', (approvalSettings) => approvalSettings?.toRep());
      map.update('resourceApprovalSettings', (settings) => settings?.map((value) => value.toRep()));
    });
  }
}

export const filterEnvironment = (env: Environment, term: string) => {
  const fields = [env.name, env.key, env._id].concat(env.tags.toJS());
  return fields.some((f) => stringContains(f, term));
};

export function filterEnvironments(
  environments: List<Environment>,
  textFilter: string,
  tagFilter: Set<string>,
  sort?: string,
) {
  let collection = environments;

  if (textFilter !== '') {
    collection = collection.filter(makeFilter(textFilter, 'name', 'key'));
  }

  if (!tagFilter.isEmpty()) {
    collection = collection.filter((p) => p.tags.isSuperset(tagFilter));
  }

  if (sort) {
    if (sort === '-createdOn') {
      collection = collection.reverse();
    }

    if (sort === 'name' || sort === '-name') {
      collection = collection.sort((a, b) => {
        if (sort === 'name') {
          return a.name.localeCompare(b.name);
        }
        if (sort === '-name') {
          return b.name.localeCompare(a.name);
        }

        return 0;
      });
    }
  }

  return collection;
}

export function createEnvironment(props?: CreateFunctionInput<Environment>) {
  const env = props instanceof Environment ? props : new Environment(fromJS(props));
  const color = props?.color ? () => createColor(props?.color as string) : createColor;

  return env.withMutations((e) => {
    e.update('color', color);
    e.update('_pubnub', (p) => new PubNub(p));
    e.update('tags', (tags) => (tags ? tags.toSet() : Set()));
    e.update('approvalSettings', createApprovalRequestSettings);
    if (enableSegmentApprovalSettings()) {
      e.update('resourceApprovalSettings', (settings) => {
        let resourceApprovalSettings = Map<ApprovalResourceKind, ResourceApprovalSettings>();

        Object.entries(ApprovalResourceKind).forEach(([, resourceKind]) => {
          const approvalSettings = createResourceApprovalRequestSettings(settings?.get(resourceKind));
          resourceApprovalSettings = resourceApprovalSettings.set(resourceKind, approvalSettings);
        });

        return resourceApprovalSettings;
      });
    }
    // As a part of 2021 pricing changes, these two updates are dependent upon their respective flags state
    e.update('requireComments', (requireComments) => (isRequireCommentsEnabled() ? requireComments : false));
    e.update('confirmChanges', (confirmChanges) => (isRequireChangeConfirmationEnabled() ? confirmChanges : false));
  });
}

type CreateProjectAndEnvironmentProps = {
  currentEnvironment: CreateFunctionInput<Environment>;
  currentProject: CreateFunctionInput<Project>;
};

export function createProjectAndEnvironment(props: CreateProjectAndEnvironmentProps) {
  return fromJS(props).withMutations((projAndEnv: ImmutableMap<CreateProjectAndEnvironmentProps>) => {
    projAndEnv.update('currentEnvironment', (e) => createEnvironment(e));
    projAndEnv.update('currentProject', (p) => createProject(p));
  });
}

export function createRandomEnvironment(props: CreateFunctionInput<Environment>) {
  const env = createEnvironment(props);
  const color = () => createColor('ffffff');
  return env.update('color', color);
}

export function canCreateEnvironment(profile: Member, project: Project) {
  const numEnvironments = project.environments.size;

  //customers on the legacy start up plan cannot create more than 2 environments
  if (numEnvironments >= 2 && !isEnvironmentCreationEnabled()) {
    return false;
  }

  return profile.hasWriterRights();
}

export const getProjectForEnvById = (envId: string | undefined, projects: OrderedMap<string, Project>) =>
  projects.find((p) => !!envId && p.environments.includes(envId));

export const getProjectForEnv = (env: Environment, projects: OrderedMap<string, Project>) =>
  getProjectForEnvById(env._id, projects);

export const environmentColorSwatches = [
  'd9f9eb',
  'fcffe1',
  'e2e6ff',
  'ffe1e9',
  'e2f9fd',
  'f1e5fa',
  'd1d3d4',
  '00da7b',
  'ebff38',
  '405bff',
  'ff386b',
  '3dd6f5',
  'a34fde',
  '939598',
];

export const environmentColorNames = {
  d9f9eb: 'Green 200',
  fcffe1: 'Yellow 200',
  e2e6ff: 'Blue 200',
  ffe1e9: 'Pink 200',
  e2f9fd: 'Cyan 200',
  f1e5fa: 'Purple 200',
  d1d3d4: 'Gray 200',
  '00da7b': 'Green 500',
  ebff38: 'Yellow 500',
  '405bff': 'Blue 500',
  ff386b: 'Pink 500',
  '3dd6f5': 'Cyan 500',
  a34fde: 'Purple 500',
  939598: 'Gray 500',
};

export const environmentColorSwatchMap = keyBy(environmentColorSwatches);

// When creating new environments we cycle through the list of default swatches
export const getNextEnvironmentColor = (environmentColors: string[]) => {
  const colorMap = {} as { [key: string]: number };
  environmentColors.map((color: string) => {
    if (environmentColorSwatchMap[color]) {
      if (colorMap[color]) {
        colorMap[color]++;
      } else {
        colorMap[color] = 1;
      }
    }
    return color;
  });

  let nextColor = environmentColorSwatches[0];
  for (let i = 0; i < environmentColorSwatches.length; i++) {
    const prevColorCount = i === 0 ? 0 : colorMap[environmentColorSwatches[i - 1]];
    const color = environmentColorSwatches[i];
    if (!colorMap[color]) {
      nextColor = environmentColorSwatches[i];
      break;
    }
    if (colorMap[color] < prevColorCount) {
      nextColor = color;
      break;
    }
  }

  return nextColor;
};

type TrackEnvironmentColorEventAttributes = {
  color: string;
  type: string;
};

export const trackEnvironmentEvent = (event: string, attributes?: TrackEnvironmentColorEventAttributes) => {
  createTrackerForCategory('Environment')(event, attributes);
};
