import { GetTagsOpenAPIQueryParams } from '../generated/operations/raw-openapi-query-params-for-serializers';

export const apiVersion = 'beta';

export interface GetTagsQueryParams extends Omit<GetTagsOpenAPIQueryParams, 'kind'> {
  kind?: 'flag' | 'project' | 'environment' | 'segment';
}

export function querySerializer(queryParams: GetTagsQueryParams) {
  const searchParams = new URLSearchParams();

  if (queryParams.archived) {
    searchParams.set('archived', String(queryParams.archived));
  }

  if (queryParams.pre) {
    searchParams.set('pre', queryParams.pre);
  }

  if (queryParams.kind) {
    searchParams.set('kind', queryParams.kind);
  }

  return searchParams.toString();
}
