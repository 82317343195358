import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { patchLayer } from './internal/patchLayer';
import { layers } from './internal/queries';

export const useUpdateLayer = createMutationHook({
  mutationKey: layers.update._def,
  mutationFn: patchLayer,
  onSuccess: async () => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: layers.list._def });
  },
});
