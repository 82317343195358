import { getClient } from './internal/client';

export function getPublicClient() {
  const client = getClient();

  return {
    PUT: client.PUT,
    POST: client.POST,
    DELETE: client.DELETE,
    PATCH: client.PATCH,
  };
}
