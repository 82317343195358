import { LayerPatchInstruction } from '@gonfalon/instructions/src/types';
import { getClient } from '@gonfalon/openapi';

import { reactQueryResponseAdapter } from './reactQueryResponseAdapter';

export async function patchLayer({
  projectKey,
  layerKey,
  input,
}: {
  projectKey: string;
  layerKey: string;
  input: { comment?: string; environmentKey?: string; instructions: LayerPatchInstruction[] };
}) {
  return reactQueryResponseAdapter(
    getClient().PATCH('/api/v2/projects/{projectKey}/layers/{layerKey}', {
      headers: { 'LD-API-Version': 'beta' },
      params: {
        path: { projectKey, layerKey },
      },
      body: {
        comment: input.comment,
        environmentKey: input.environmentKey,
        instructions: input.instructions as unknown as Array<{ [key: string]: unknown }>,
      },
    }),
  );
}
