import { getQueryClient } from '@gonfalon/react-query-client';

import { createMutationHook } from './internal/createMutationHook';
import { patchIssue } from './internal/patchIssue';
import { monitoringIssuesList } from './internal/queries';

export const useUpdateIssue = createMutationHook({
  mutationFn: patchIssue,
  onSuccess: async (data, { projectKey }) => {
    const queryClient = getQueryClient();
    await queryClient.invalidateQueries({ queryKey: monitoringIssuesList.partialQueryKey({ projectKey }) });
  },
});
